.dsbvo-page-holder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dsbvo-content-container {
  margin-top: 75px;
  width: 100%;
  max-width: 1560px;
  height: 100%;
  margin: 95px 24px;
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: baseline;
  justify-content: baseline;
}

.dsbvo-heading-holder h1 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.dsbvo-welcome-holder {
  margin-bottom: 25px;
}

.ordered-list-holder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: baseline;
}

.list-count-holder {
  margin-right: 20px;
  margin-top: 25px;
}

.wording-span {
  font-weight: 700;
  font-size: 1.2rem;
}

.revoke-mail-link {
  color: #1e3799;
}

.revoke-mail-link:hover {
  text-decoration: underline;
}

.data-span {
  font-weight: 700;
  font-size: 1.2rem;
}

.data-list-holder {
  display: grid;
  grid-template-columns: repeat(1, auto);
  margin: 15px 0;
  align-items: baseline;
  justify-items: baseline;
}

.data-list-row-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 2.5ch;
}

.list-dot {
  margin-right: 25px;
}

.right-ol-holder {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: baseline;
  list-style: none;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .data-span {
    font-weight: 700;
    font-size: 1rem;
  }

  .wording-span {
    font-weight: 700;
    font-size: 1rem;
  }

  p {
    width: 90%;
  }

  h3 {
    width: 90%;
  }
}
