/* service-slider.css */

.service-slider-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.service-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-card-container img {
  margin-bottom: 25px;
}

.list-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.slick-list {
  max-width: 1560px;
}
.slick-slide {
  padding: 0;
}

.service-title {
  font-size: 28px;
  font-weight: 700;
}

.service-content {
  font-weight: 1rem;
  font-weight: 500;
  text-align: center;
  max-width: 28ch;
}

.reference-image {
  margin-right: 25px;
}

.service-card {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
  transition: transform 0.2s;
}

@media screen and (max-width: 700px) {
  .list-holder {
    width: 100%;
    margin: 0 auto;
  }

  .custom-next-arrow {
    display: none;
  }

  .custom-prev-arrow {
    display: none;
  }

  .service-card-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 25px;
  }

  .service-card {
    width: 100vw;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #fff;
    transition: transform 0.2s;
  }

  .service-content {
    width: auto;
    font-weight: 1rem;
    font-weight: 500;
    max-width: 30ch;
  }

  .service-slide {
    width: 100%;
    margin: 0 auto;
  }

  .slick-track {
    overflow-x: hidden !important;
  }
}
