*,
*::before,
*::after {
  box-sizing: border-box;
}

header {
  background-color: #1a273b;
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  width: 100%;
  height: 100px;
  padding: 25px;
  z-index: 999;
}

.main-header {
  background-color: transparent;
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  width: 100%;
  height: 100px;
  padding: 25px;
  z-index: 999;
}
.main-header.scrolled {
  background-color: #1a273b;
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
  width: 100%;
  height: 100px;
  padding: 25px;
  z-index: 999;
}

.nav-wrapper {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 1560px;
}

.contact-nav-button {
  text-decoration: none;
  height: auto;
  margin-left: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-right: 1rem;
}

nav {
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

nav ul {
  display: flex;
  list-style-type: none;
  gap: 1rem;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

#contact-button-link {
  text-decoration: none;
}

#contact-button-link::after {
  text-decoration: none;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  padding: 2px 0;
  transition: all 0.2s;
  position: relative;
  margin-right: 10px;
}

.nav-link-mobile {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 700;
  padding: 2px 0;
  position: relative;
}

.nav-link:hover {
  width: 100%;
  color: grey;
}

.loeffler-mueller-logo-container {
  color: #ffffff;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
}

.loeffler-mueller-logo-container h1 {
  font-size: 48px;
}

.logo-img {
  width: 325px;
  height: 60px;
  margin-left: 3px;
}

.toggle-menu {
  position: absolute;
  width: 55px;
  right: 0;
  height: 100%;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
}

.hamburger-line {
  position: relative;
  width: 15px;
  height: 2px;
  background: white;
  color: transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
}

.hamburger-line::before,
.hamburger-line::after {
  content: "";
  position: absolute;
  height: 2px;
  background: white;
  border-radius: 10px;
}

.hamburger-line,
.toggle-menu {
  display: none;
}

.hamburger-line::before {
  transform: translateY(-5px);
  width: 15px;
  border-radius: 10px;
  transition: 0.5s;
}

.hamburger-line::after {
  transform: translateY(5px);
  width: 15px;
  border-radius: 10px;
  transition: 0.5s;
}

nav input:checked ~ .hamburger-line {
  background: transparent;
}

nav input:checked ~ .hamburger-line::before {
  transform: rotate(-45deg);
  width: 15px;
}

nav input:checked ~ .hamburger-line::after {
  transform: rotate(45deg);
  width: 15px;
}

.mobileMenu {
  position: fixed;
  top: -100vh;
  right: 0;
  transition: 0.5s;
  z-index: 997;
}

@media screen and (max-width: 1150px) {
  header {
    background-color: #1a273b;
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    width: 100vw;
    height: 50px;
    padding: 25px;
    z-index: 999;
  }
  .main-header {
    background-color: #1a273b;
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    width: 100vw;
    height: 50px;
    padding: 25px;
    z-index: 999;
  }
  .main-header.scrolled {
    background-color: #1a273b;
    display: flex;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: space-between;
    padding-right: 25px;
    width: 100vw;
    height: 50px;
    padding: 25px;
    z-index: 999;
  }
  nav {
    align-items: center;
    justify-content: end;
  }
  .toggle-menu {
    display: block;
    z-index: 999;
  }
  .hamburger-line {
    display: block;
    z-index: 999;
  }
  nav ul {
    display: none;
    transition: 0.8s;
  }
  .contact-nav-button {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  nav input:checked ~ .mobileMenu {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    z-index: 997;
    transition: 0.5s;
  }
  nav input:checked ~ .hamburger-line {
    position: fixed;
  }
  .mobileMenuList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    z-index: 996;
    padding: 40px;
    color: white;
    background-color: #1a273b;
    margin: 0;
    font-weight: 700;
    overflow: hidden;
  }
  nav ul li a {
    font-size: 32px;
  }
  nav ul li {
    margin-bottom: 24px;
  }
  .loeffler-mueller-logo-container {
    color: #ffffff;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
  }
  .loeffler-mueller-logo-container h1 {
    font-size: 1rem;
  }
  .logo-img {
    width: 162.5px;
    height: 30px;
  }
  .computerMenu {
    display: none;
  }
}
