#carousel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
}
#carousel-wrapper #menu {
  height: 100%;
  width: 100%;
  font-weight: 700;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease-in-out;
}
#carousel-wrapper #menu #current-option {
  position: relative;
  width: 60ch;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
}
#carousel-wrapper #menu #current-option #current-option-text1 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 60ch;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
}
#carousel-wrapper #menu #current-option #current-option-text1::before {
  content: attr(data-next-text);
  position: relative;
  transform: translate(0%, 380px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#carousel-wrapper #menu #current-option #current-option-text1::after {
  content: attr(data-previous-text);
  position: relative;
  transform: translate(0%, -380px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#carousel-wrapper #menu #current-option #current-option-text2 {
  font-size: 1rem;
  width: 60ch;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
#carousel-wrapper #menu #current-option #current-option-text2::before {
  content: attr(data-next-text);
  position: relative;
  transform: translate(780px, 0%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
#carousel-wrapper #menu #current-option #current-option-text2::after {
  content: attr(data-previous-text);
  position: relative;
  transform: translate(-780px, 0%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
#carousel-wrapper #menu #previous-option {
  width: 45px;
  height: 45px;
  border: none;
  display: block;
  cursor: pointer;
  background: url("../assets/icon/prev.png");
  background-size: cover;
  position: relative;
}
#carousel-wrapper #menu #next-option {
  width: 45px;
  height: 45px;
  border: none;
  display: block;
  cursor: pointer;
  background: url("../assets/icon/next.png");
  background-size: cover;
  position: relative;
}
#carousel-wrapper #image {
  height: 240px;
  width: 240px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 101;
  position: relative;
  border-radius: 50%;
}
#carousel-wrapper #imagePrev {
  height: 120px;
  width: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 101;
  border-radius: 50%;
  position: relative;
}
#carousel-wrapper #imageNext {
  height: 120px;
  width: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 101;
  border-radius: 50%;
  position: relative;
}
#carousel-wrapper.anim-next {
  pointer-events: none;
}
#carousel-wrapper.anim-next #current-option-text1 {
  animation: next-text 0.65s 0.085s;
}
#carousel-wrapper.anim-next #current-option-text2 {
  animation: next-text 0.65s 0.085s;
}
#carousel-wrapper.anim-next #previous-option {
  animation: next-top-arrow 0.65s 0.085s;
}
#carousel-wrapper.anim-next #next-option {
  animation: next-bottom-arrow 0.65s 0.085s;
}
#carousel-wrapper.anim-next #image {
  animation: next-image 0.65s 0.085s;
}
#carousel-wrapper.anim-previous {
  pointer-events: none;
}
#carousel-wrapper.anim-previous #current-option-text1 {
  animation: previous-text 0.65s 0.085s;
}
#carousel-wrapper.anim-previous #current-option-text2 {
  animation: previous-text 0.65s 0.085s;
}
#carousel-wrapper.anim-previous #image {
  animation: previous-image 0.65s 0.085s;
}

.team-member-holder {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.menu-icon-holder {
  position: relative;
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.menu-items-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@keyframes previous-text {
  50%,
  55% {
    transform: translate(390px, 0%);
  }
  to {
    transform: translate(380px, 0%);
  }
}
@keyframes previous-image {
  0% {
    transform: translate(140px, 0) scale(1);
    opacity: 1;
  }
  70% {
    transform: translate(140px, 0) scale(1.1);
    opacity: 0;
  }
  100% {
    transform: translate(140px, 0) scale(1);
    opacity: 1;
  }
}
@keyframes next-text {
  50%,
  55% {
    transform: translate(390px, 0%);
  }
  to {
    transform: translate(-380px, 0%);
  }
}
@keyframes next-image {
  0% {
    transform: translate(140px, 0) scale(1);
    opacity: 1;
  }
  70% {
    transform: translate(140px, 0) scale(1.1);
    opacity: 0;
  }
  100% {
    transform: translate(140px, 0) scale(1);
    opacity: 1;
  }
}
.team-slider-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.team-slider-container {
  width: auto;
  height: auto;
  position: relative;
}

.team-slick-slider-bottom-navigation-holder {
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 100px;
}

.custom-prev-arrow {
  position: relative;
  z-index: 996;
  width: auto;
}

.custom-next-arrow {
  position: relative;
  z-index: 996;
  width: auto;
}

.custom-next-arrow:hover {
  cursor: pointer;
}

.custom-prev-arrow:hover {
  cursor: pointer;
}

.holder {
  margin: 0 auto;
  width: 100%;
  max-width: 1560px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.team-image-slider {
  width: 100%;
  max-width: 780px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.team-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.team-name {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 50px;
}

.team-description {
  font-size: 1.2rem;
  font-weight: 500;
  max-width: 40ch;
}

.team-content {
  font-size: 18px;
  font-weight: 500;
  max-width: 780px;
}

.slick-list {
  overflow-x: hidden;
}

.slick-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1920px;
  margin: 0 auto;
}

.slick-slider-prev-button {
  border: none;
  background: transparent;
  width: auto;
  height: auto;
}

.slick-slider-next-button {
  border: none;
  background: transparent;
  width: auto;
  height: auto;
}

.team-center-image {
  outline: 45px solid white;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: 0%;
  border-radius: 50%;
  width: 400px;
  height: 400px;
}

.team-outside-image {
  margin: -85px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  filter: blur(5px);
  border-radius: 50%;
  width: 250px;
  height: 250px;
}

.team-outside-image.active {
  opacity: 1;
}

.pause-button-team-slider {
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
}

.pause-button-team-slider:hover {
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 1780px) {
  .team-outside-image {
    display: none;
    width: 0px;
    height: 0px;
    position: absolute;
    visibility: hidden;
  }
  .team-outside-image.active {
    opacity: 0;
  }
  .team-center-image {
    outline: none;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    border-radius: 50%;
    width: 300px;
    height: 300px;
  }
  .holder {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
  }
  .team-content {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .team-image-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
  }
  .team-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .team-name {
    text-align: center;
    margin: 0;
  }
  .team-description {
    text-align: center;
  }

  .team-slick-slider-bottom-navigation-holder {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .team-outside-image {
    display: none;
    width: calc(50% - 125px);
    height: 0px;
    visibility: hidden;
  }
  .team-outside-image.active {
    opacity: 0;
  }
  .team-center-image {
    outline: none;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: 0%;
    border-radius: 50%;
    width: 250px;
    height: 250px;
  }
  .holder {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 25px;
  }
  .team-content {
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .team-image-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
  }
  .team-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .team-name {
    text-align: center;
    margin: 0;
  }
  .team-description {
    text-align: center;
  }

  .team-slick-slider-bottom-navigation-holder {
    width: 50%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
}

@media screen and (max-width: 1780px) {
  .custom-prev-arrow {
    margin: 0 12px;
  }
  .custom-next-arrow {
    margin: 0 12px;
  }
}
