.nf-container {
  width: 100vw;
  height: 100vh;
  background-color: #1a273b;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nf-heading,
.nf-paragraph,
.nf-link {
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 0;
  margin: 0;
}

.nf-heading {
  font-size: 96px;
}

.nf-link {
  text-decoration: none;
  margin-top: 64px;
}

.nf-link:hover {
  text-decoration: underline;
}

.nf-logo {
  width: 700px;
  height: auto;
  max-width: 100%;
}

.acryl-container {
  padding: 64px 24px;
  position: absolute;
  border-radius: 16px;
  background-color: rgba(12, 12, 12, 0.3);
  backdrop-filter: blur(24px) saturate(180%) brightness(60%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
