*,
*::before,
*::after {
  box-sizing: border-box;
}

.contactPage {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.infoGrid {
  margin: 0 auto;
  gap: 20px;
  width: 80%;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contactInfo {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  margin-top: 18px;
  margin-bottom: 18px;
  text-decoration: none;
  color: #0056b3;
}

.contact-icon {
  margin-right: auto;
}

.contactInfo:hover {
  color: #0056b3;
  text-decoration: underline;
}

.infoCard {
  width: auto;
  padding-left: 25px;
  padding-right: 25px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
}

.contact-form {
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.form-heading {
  font-size: 3rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 25px;
  box-sizing: border-box;
  font-size: 16px;
}

.inputGroup {
  width: 100%;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.send-button {
  background-color: rgba(72, 126, 176, 0.5);
  border: 1px solid rgba(25, 42, 86, 1);
  width: auto;
  height: auto;
  color: rgba(39, 60, 117, 1);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 25px;
}

.send-button:hover {
  background-color: rgba(25, 42, 86, 0.5);
  border: 1px solid #0056b3;
}

.send-button:disabled,
.send-button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: auto;
}

.invalid {
  border-color: red;
}

.agb-consent-holder {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.agb-consent-holder p {
  width: 80%;
  padding: 0;
  margin: 0;
  line-height: normal;
}

.agb-consent-checkbox {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

@media screen and (max-width: 1440px) {
  .infoGrid {
    margin: 0 auto;
    gap: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputGroup {
    width: 100%;
  }

  .contactPage h1 {
    font-size: 32px;
  }

  .infoCard {
    width: 40%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    display: flex;
    flex-direction: row;
  }

  .infoCard img {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 900px) {
  .infoGrid {
    margin: 0 auto;
    gap: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputGroup {
    width: 100%;
  }

  .contactPage h1 {
    font-size: 32px;
  }

  .infoCard {
    width: 60%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    display: flex;
    flex-direction: row;
  }

  .infoCard img {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 650px) {
  .infoGrid {
    margin: 0 auto;
    gap: 20px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .inputGroup {
    width: 100%;
  }

  .contactPage h1 {
    font-size: 32px;
  }

  .infoCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    display: flex;
    flex-direction: row;
  }

  .infoCard img {
    width: 25px;
    height: 25px;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 25px;
    box-sizing: border-box;
    font-size: 1rem;
  }

  button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 15px 32px;
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }

  .contactInfo a {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 24px;
    margin-top: 18px;
    margin-bottom: 18px;
    text-decoration: none;
    color: #0056b3;
  }

  .contactInfo a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
}
