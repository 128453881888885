@charset "UTF-8";

.landing-page-body {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

.landingpage-section-holder {
  width: 100%;
  height: 800px;
}

.landingpage-landing-section-holder {
  width: 100%;
  height: auto;
  background-color: #1a273b;
}

.landingpage-landing-section-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.landing-section-heading-holder {
  position: absolute;
  left: 0;
  width: auto;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  color: white;
}

.about-us-section {
  width: 100%;
  height: 100%;
  min-height: 700px;
  background-color: white;
  color: black;
  height: auto;
}

.img-hero {
  position: relative;
  width: 1560px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.landing-section-img-holder {
  margin-left: auto;
  width: 900px;
  height: 900px;
  background-image: url("../assets/icon/Bildmarke_Löffler_undMüller_weiß.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 900px 900px;
}

.landing-section-heading-holder h1 {
  font-size: 64px;
  max-width: 17ch;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.read-more-link {
  color: #084e9c;
  font-size: 20px;
  font-weight: 900;
}

.about-us-credentials-holder {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1560px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
}

.about-us-heading-holder {
  width: 100%;
  height: auto;
  padding: 25px 150px;
}

.about-us-heading-holder h1 {
  font-size: 48px;
  font-weight: 700;
}

.about-us-credentials-holder {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: baseline;
  margin: 0 auto;
  gap: 56px;
}

.about-us-credentials-content-left {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-credentials-content-right {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-credentials-content-image-holder-left {
  width: 300px;
  height: 150px;
  background-image: url("../assets/icon/Loeffler-Logo.png");
  background-size: contain;
  background-position: 0%;
  background-repeat: no-repeat;
}

.about-us-credentials-content-image-holder-right {
  width: 300px;
  height: 150px;
  background-image: url("../assets/icon/Muller-Logo.png");
  background-size: contain;
  background-position: 0%;
  background-repeat: no-repeat;
}

.about-us-credentials-content-paragraph-left {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-us-credentials-content-paragraph-right {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.credentials-heading {
  font-size: 28px;
}

.credentials-main-content {
  color: rgb(99, 110, 114);
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  max-width: 40ch;
}

.credentials-website-link {
  color: #084e9c;
  padding: 0;
  margin: 0;
  margin-top: 25px;
  font-weight: 700;
}

.credentials-website-link-explaination {
  color: grey;
  padding: 0;
  margin: 0;
  font-size: 0.7rem;
}

.service-section {
  height: auto;
  padding: 50px 0px;
  border-top: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section-heading-holder {
  width: 100%;
  height: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 64px 0px;
}

.carrier {
  color: white;
  text-decoration: none;
}

.carrier:hover {
  text-decoration: underline;
}

.carrier-section-heading-holder {
  width: 100%;
  height: max-content;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.section-heading {
  font-size: 48px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-block-start: 0px;
  width: fit-content;
}

.section-heading-carrier {
  font-size: 48px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-block-start: 0px;
  width: fit-content;
}

.section-content-holder {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goal-section {
  width: 100%;
  height: auto;
  background-color: #1a273b;
  color: white;
  height: auto;
  padding: 50px 24px;
}

.goal-section-content-holder {
  width: 100%;
  height: 700px;
  padding: 48px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.carrier-section-content-holder {
  width: 100%;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.goal-content-horiz-holder {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.goal-content-content {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.goal-content-heading {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 35px;
}

.goal-content-paragraph {
  font-size: 18px;
  margin: 0;
  padding: 0;
  max-width: 50ch;
}

.carrier-content-paragraph {
  font-size: 18px;
  margin: 28px 0px;
  padding: 0;
}

.goal-content-image-holder {
  width: 700px;
  height: 700px;
  border-radius: 50%;
  background-image: url("../assets/img/Auftrag.jpg");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.goal-image {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: 50%;
}

.reference-section {
  width: 100%;
  height: auto;
  padding: 50px 0px;
  border-top: 1px solid #cbcbd4;
  border-bottom: 1px solid #cbcbd4;
}

.reference-heading-holder {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 55px;
}

.refeernce-subheading {
  font-size: 18px;
  color: grey;
}

.reference-heading {
  font-size: 48px;
  font-weight: 900;
}

.reference-slider-card {
  width: 500px;
  height: 250px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: baseline;
  text-align: left;
}

.reference-slider-card-img-holder {
  width: 150px;
  height: 150px;
  margin-right: 25px;
  background-image: url("../assets/img/Montage.jpg");
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.team-section {
  width: 100%;
  height: auto;
  padding: 50px 0px;
  border-top: 1px solid #cbcbd4;
  border-bottom: 1px solid #cbcbd4;
}

.team-content-holder {
  width: 100%;
  height: auto;
  padding: 25px 150px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.team-heading {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 80px;
}

.contact-interest-section {
  width: 100%;
  height: 100%;
  max-width: 2440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  background-image: url("../assets/img/Montage.jpg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 50%;
}

.contact-interest-content {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.interest-heading {
  font-size: 56px;
  font-weight: 900;
}

.interest-button {
  background-color: rgba(72, 126, 176, 0.5);
  border: 1px solid rgb(25, 42, 86);
  border-radius: 5px;
  padding: 20px 15px;
  color: rgb(39, 60, 117);
  font-size: 1.3rem;
  font-weight: 500;
}

.interest-button:hover {
  background-color: rgba(25, 42, 86, 0.5);
  border: 1px solid #0056b3;
}

.slick-slide {
  padding: 15px;
}

@media screen and (max-width: 1290px) {
  .landing-section-heading-holder h1 {
    font-size: 1em;
    text-align: left;
    padding: 0 24px;
    margin: 0;
    width: 100%;
    z-index: 995;
  }

  .landing-section-img-holder {
    width: 700px;
    height: 700px;
    left: 20% !important;
    display: flex;
    align-items: flex-start;
    background-image: url("../assets/icon/Bildmarke_Löffler_undMüller_weiß.png");
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: contain;
    position: absolute;
    opacity: 1;
  }
}

@media screen and (max-width: 656px) {
  .landing-section-heading-holder h1 {
    font-size: 2.5em !important;
    text-align: left;
    padding: 0 24px;
    margin: 0;
    z-index: 995;
    max-width: 90%;
  }

  .landing-section-img-holder {
    width: 700px;
    height: 700px;
    left: 0 !important;
    display: flex;
    align-items: flex-start;
    background-image: url("../assets/icon/Bildmarke_Löffler_undMüller_weiß.png");
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: contain;
    position: absolute;
    opacity: 1;
  }
}

@media screen and (max-width: 304px) {
  .landing-section-heading-holder {
    width: 100%;
  }

  .landing-section-heading-holder h1 {
    font-size: 1em;
    text-align: left;
    padding: 0 24px;
    margin: 0;
    width: 100%;
    z-index: 995;
  }
}

@media screen and (max-width: 1626px) {
  .landing-section-heading-holder {
    width: 100%;
  }

  .landing-section-heading-holder h1 {
    font-size: 3em;
    text-align: left;
    padding: 0 24px;
    margin: 0;
    width: 100%;
    z-index: 995;
  }

  .team-slick-slider-bottom-navigation-holder {
    width: 100% !important;
  }

  .slick-center {
    border: none;
    background-color: transparent;
  }

  .section-heading {
    text-align: center;
    margin: 0 auto;
    padding: 0;
  }

  .section-heading-carrier {
    text-align: start;
    padding: 0;
  }

  .section-heading-holder {
    margin: 64px auto;
  }

  .goal-content-paragraph {
    text-align: center;
    margin: 96px auto;
  }

  .carrier-content-paragraph {
    text-align: center;
    margin: 28px 0px;
    max-width: 112ch;
  }

  .landingpage-landing-section-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .landing-section-img-holder {
    width: 700px;
    height: 700px;
    float: right;
    left: 50%;
    display: flex;
    align-items: flex-end;
    background-image: url("../assets/icon/Bildmarke_Löffler_undMüller_weiß.png");
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: contain;
    position: absolute;
    opacity: 1;
  }

  .about-us-credentials-holder {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-us-section {
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    height: auto;
    padding-bottom: 32px;
  }

  .goal-section {
    background-color: #1a273b;
    color: white;
    height: auto;
    padding: 50px 0px;
    border-top: 1px solid #cbcbd4;
    border-bottom: 1px solid #cbcbd4;
  }

  .goal-section-content-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .carrier-section-content-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .goal-content-content {
    width: auto;
    height: auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .goal-content-horiz-holder {
    display: flex;
    flex-direction: column;
  }

  .goal-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: 50%;
    margin-bottom: 50px;
  }

  .contact-interest-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    background: transparent;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .interest-image-holder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: baseline;
    justify-content: baseline;
  }

  .interest-heading {
    font-size: 2rem;
    font-weight: 900;
  }

  .contact-interest-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 15px;
    justify-content: space-evenly;
    backdrop-filter: blur(24px) brightness(70%) saturate(180%);
  }

  .credentials-heading {
    margin: 0 auto;
  }

  .credentials-website-link {
    margin: 0 auto;
  }

  .about-us-credentials-content-left {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    text-align: center;
  }

  .about-us-credentials-content-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    text-align: center;
  }

  .pdf-link {
    color: inherit;
    /* oder `inherit` falls es die gleiche Farbe wie umliegender Text sein soll */
    text-decoration: none;
  }

  .pdf-link:hover {
    text-decoration: underline;
  }
}