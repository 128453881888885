.nav-dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-dot {
  height: auto;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.nav-dot {
  background: transparent;
  width: 10px;
  height: 10px;
  margin: 10px auto;
  padding: 0;
}

.nav-dot:hover {
  background: transparent;
}

.nav-dot.active {
  background: transparent;
}

.service-dot {
  background: transparent;
  width: 10px;
  height: 10px;
  margin: 10px;
  padding: 0;
}

.service-dot:hover {
  background: transparent;
}

.service-dot.active {
  background: transparent;
}

button {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

.dot {
  width: 10px;
  height: 10px;
  border: none;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot:hover {
  cursor: pointer;
}

.dot.active {
  background-color: #1a273b;
  transform: scale(1.2);
}
