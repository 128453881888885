.footer {
  color: #121212;
  font-size: 0.8rem;
  text-align: center;
  position: relative;
  width: 100%;
  height: auto;
  border-top: 2px solid #1a273b;
}

.footer-content-holder {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #1a273b;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.footer-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1560px;
  margin: 0 auto;
}

.footer-text {
  margin: 0;
}

.footer p {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.footer ul {
  display: flex;
  list-style-type: none;
  width: auto;
  padding: 0;
  margin: 0;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
}

.footer-cred-section {
  height: auto;
  padding: 40px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1560px;
  margin: 0 auto;
  justify-content: space-between;
}

.footer-left-holder {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
}

.footer-partner-text {
  width: 650px;
  font-size: 1.5rem;
  font-weight: 600;
}

.footer-right-holder {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
}

.cred-content-holder {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: center;
  gap: 11px;
}

.cred-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  margin-left: 25px;
  padding: 0;
  height: 100%;
}

.cred-content p {
  text-align: left !important;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.cred-content a {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.cred-left-holder {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
  gap: 20px;
}

.cred-right-holder {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: baseline;
}

.social-icon-holder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
}

.footer-l-m-logo {
  width: 650px;
  height: 120px;
}

.vert-divivider {
  width: 1px;
  height: 3ch;
  background-color: white;
}

.footer a {
  color: #121212;
  text-decoration: none;
  line-height: 2;
  font-size: 18px;
  font-weight: 500;
}

.footer a:hover {
  text-decoration: underline;
}

.footer-link-list {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: space-evenly;
}

@media screen and (max-width: 1787px) {
  .footer-content-holder {
    padding: 0 24px;
  }

  .footer-cred-section {
    padding: 24px 0px;
  }
}

@media screen and (max-width: 1117px) {
  .footer-content-holder {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
    padding: 24px;
  }

  .cred-content-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 11px;
  }

  .cred-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    padding: 0;
    margin: 0;
    gap: 6px;
    height: 100%;
  }

  .cred-content p {
    text-align: center !important;
    margin: 0;
    padding: 0;
    font-size: 20px;
  }

  .footer-left-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  .footer-right-holder {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .footer p {
    text-align: center;
  }

  .footer a {
    color: #121212;
    text-decoration: none;
    line-height: 2.6ch;
  }

  .footer ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: auto;
    height: auto;
    gap: 14px;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .cred-left-holder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    gap: 32px;
  }

  .footer-cred-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
  }

  .footer-partner-text {
    width: 80%;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .footer-l-m-logo {
    width: 350px;
    height: 65px;
  }

  .footer-content {
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
  }
}

.footer-last-link {
  border-right: none !important;
}

.footer-last-link-a {
  padding-right: 0px !important;
}

.footerHolder {
  min-height: 100%;
  overflow: auto;
}

.footerBlueLink {
  color: #3a68ff !important;
}

.footerBlueLink:hover {
  text-decoration: underline;
  color: #3a68ff;
}
